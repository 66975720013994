import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NgZorroModule } from './ng-zorro.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SanitizerPipe } from '../services/helper/sanitizer.pipe';

@NgModule({
  declarations: [SanitizerPipe],
  imports: [CommonModule, ],
  exports: [
    TranslateModule, 
    NgZorroModule,
    ReactiveFormsModule,
    SanitizerPipe
  ],
})
export class SharedModule {}
